import React, { useState, useMemo } from 'react';
import HTMLReactParser from 'html-react-parser';
import "./ReadMore.scss";

const ReadMoreComponent = ({ content, maxlength, wrapclass }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const MAX_LENGTH = maxlength || 350;

  const toggleReadMore = () => {
    setIsExpanded(prevState => !prevState);
  };

  // Memoize the content to avoid recalculating on every render
  const displayedContent = useMemo(() => {
    const contentLengthExceeds = content.length > MAX_LENGTH;

    return isExpanded
      ? content.replace(/\n/g, '')
      : content.slice(0, MAX_LENGTH).replace(/\n/g, '') + (contentLengthExceeds ? '... ' : ' ');
  }, [content, isExpanded, MAX_LENGTH]);

  const contentLengthExceeds = content.length > MAX_LENGTH;

  const getLastBlockElementIndex = (htmlString) => {
    const blockElementsRegex = /<\/?(p|h1|h2|h3|h4|h5|h6|ul|ol|li|div|blockquote|figure|figcaption|section|article|nav|aside|pre|hr)>/g; // Excluding <img>
    const matches = [...htmlString.matchAll(blockElementsRegex)];
    return matches.length ? matches[matches.length - 1].index : -1; // Return the index of the last match
  };

  // Find the index of the last block-level element
  const lastBlockIndex = getLastBlockElementIndex(displayedContent);

  // Extract content before and after the last block-level element
  const contentWithoutLastBlock = displayedContent.slice(0, lastBlockIndex);
  const lastBlock = displayedContent.slice(lastBlockIndex);

  return (
    <div className={`module-content-wrapper ${wrapclass}`}>
      <div className="module-content">
        {/* Render content without the last block-level element */}
        {HTMLReactParser(contentWithoutLastBlock)}

        {/* Last block-level element with "Read More" button */}
        <div className="last-para-container">
          {HTMLReactParser(lastBlock)}

          {contentLengthExceeds && (
            <span
              className="text-sm read-more-btn"
              onClick={toggleReadMore}
              aria-expanded={isExpanded}
              aria-controls="content"
            >
              {isExpanded ? 'read less' : 'read more'}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReadMoreComponent;